import React, { CSSProperties, ReactNode, useState } from 'react';
import styles from './Tags.module.css';
import { Toolbar, Button, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { StackLayout } from '@progress/kendo-react-layout';
import DeleteProjectsDialog from '../../../components/dialogs/DeleteProjectsDialog/DeleteProjectsDialog';
import DataTable from '../../../components/main/DataTable/DataTable';
import { useGetAllProjectsQuery, useGetTagListQuery, useGetTagQuery } from '../../../generated/graphql';
import NewTagDialog from '../../../components/dialogs/NewTagDialog/NewTagDialog';
import DeleteTagsDialog from '../../../components/dialogs/DeleteTagsDialog/DeleteTagsDialog';

type TagsProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const Tags = (props: TagsProps) => {
    const { style, className } = props;
    const [searchText, setSearchText] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [deleteTagDialogVisible, setDeleteTagsDialogVisible] = useState(false);
    const [newTagVisible, setNewTagVisible] = useState(false);

    const result = useGetTagListQuery({
        variables: {
            contains: searchText,
            after: null
        }
    });
    const handleNewTagOnClose = () => {
        setNewTagVisible(false);
        result.refetch();
    };

    const handleDataTableOnSelected = (ids: string[]) => {
        setSelectedIds(ids);
    };
    const handleDeleteTagOnClose = () => {
        setDeleteTagsDialogVisible(false);
        result.refetch();
    };
    const handleSearchBoxonChanged = (value: string) => {
        setSearchText(value);
    };

    return (
        <>
            <StackLayout orientation='vertical' className={`${styles.Tags} ${className || ""}`}
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0
                }} data-testid="Tags"
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="add" fillMode="flat" themeColor={"primary"} onClick={() => setNewTagVisible(true)}>
                        Add
                    </Button>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => result.refetch()}>
                        Refresh
                    </Button>
                    {selectedIds.length > 0 && <>
                        <ToolbarSeparator />
                        <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteTagsDialogVisible(true)}>
                            Delete
                        </Button>
                    </>}
                </Toolbar>
                <DataTable
                    showDescriptionColumn
                    style={{ flex: 1 }}
                    onSelected={handleDataTableOnSelected}
                    onSearchBoxChanged={handleSearchBoxonChanged}
                    isLoading={result.loading}
                    data={result.data?.TagList?.nodes}
                    customProps={[{ id: "type", name: "Type" }]}
                    fetchMore={() => result.data?.TagList?.pageInfo?.hasNextPage && result.fetchMore({
                        variables: {
                            contains: searchText,
                            after: result.data?.TagList?.pageInfo.endCursor
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;
                            const previousContents = result.data?.TagList?.nodes || [];
                            const newContents = fetchMoreResult.TagList?.nodes || [];
                            return {
                                ...previousResult,
                                TagList: {
                                    nodes: [...previousContents, ...newContents],
                                    pageInfo: fetchMoreResult.TagList?.pageInfo as any
                                }
                            };
                        }
                    })}  />
            </StackLayout>
            <DeleteTagsDialog ids={selectedIds} visible={deleteTagDialogVisible} onClose={handleDeleteTagOnClose} />
            <NewTagDialog visible={newTagVisible} onClose={handleNewTagOnClose} />
        </>
    );
};

export default Tags;